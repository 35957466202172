<!-- 新增活动管理 -->
<template>
  <!-- data-range-keys 开始时间和结束时间的联动 -->
  <div>
    <ax-form
      ref="formBox"
      :formBuilder="formBuilder"
      :data-range-keys="dateRangeKeys"
    >
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="请选择党组织"
          change-on-select
          @change="onChange"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
      <div slot="photo" slot-scope="{ record }">
        <el-upload
          style="display: flex; align-items: flex-start"
          action="#"
          :limit="1"
          :auto-upload="false"
          list-type="picture-card"
          v-decorator="[
            record.model,
            {
              rules: record.rules,
            },
          ]"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div class="el-upload__tip" slot="tip">
            <span> 最多上传三张 </span>
            <span>照片格式小于5M</span>
            <span>支持格式jpg、png、jpeg</span>
          </div>
        </el-upload>

        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div slot="leaderName" slot-scope="{ record }" style="display: flex">
        <a-input
          disabled
          v-decorator="[
            record.model,
            {
              rules: record.rules,
            },
          ]"
          placeholder="请输入"
        ></a-input>
        <ax-button @click="chooseTeamLeader" style="margin-left: 2%"
          >选择人员</ax-button
        >
      </div>
      <div slot="endDate" slot-scope="{ record }" style="display: flex">
        <a-input
          disabled
          v-decorator="[
            record.model,
            {
              rules: record.rules,
            },
          ]"
          placeholder="请输入"
        ></a-input>
        <ax-button @click="chooseTeamLeader" style="margin-left: 2%"
          >选择人员</ax-button
        >
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
    <choose-team-leader ref="chooseTeamLeader"></choose-team-leader>
  </div>
</template>
<script>
import api from "./api";
import ChooseTeamLeader from "./chooseTeamLeader.vue";
const options = [
  { label: "党课", value: "0" },
  { label: "支部大会", value: "1" },
  { label: "支委会议", value: "2" },
  { label: "党小组会议", value: "3" },
];
const formList = [
  {
    label: "党会名称",
    type: "",
    model: "name",
    options: { placeholder: "请输入", showTime: true, maxLength: 200 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,200}$/,
        message: "党会名称不能为空",
      },
    ],
  },
  {
    label: "党会时间",
    type: "datePicker",
    model: "type",
    options: {
      placeholder: "请输入",
    },
    col: { span: 12 },
    rules: [{ required: true, message: "党会时间不能为空" }],
  },

  {
    label: "党组织",
    type: "select",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 12 },
    rules: [{ required: true, message: "党组织不能为空" }],
  },
  {
    label: "会议地点",
    type: "",
    model: "address",
    options: { placeholder: "请输入", showTime: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,50}$/,
        message: "会议地点不能为空",
      },
    ],
  },

  {
    label: "主持人",
    type: "",
    model: "leaderName",
    options: {
      placeholder: "请输入",
      showTime: true,
      maxLength: 24,
    },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,50}$/,
        message: "主持人不能为空",
      },
    ],
  },
  {
    label: "会议类型",
    type: "radio",
    model: "radio",
    col: { span: 24 },
    options: { options, placeholder: "请选择", showTime: true },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "参会人员",
    type: "select",
    model: "endDate",
    col: { span: 24 },
    options: { placeholder: "请选择", showTime: true },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "会议简介",
    type: "textarea",
    model: "attribute",
    options: { minRows: 4, maxRows: 4, placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: true, message: "活动内容不能为空" }],
  },
  {
    label: "列席人员",
    type: "",
    model: "AwardQuota",
    col: { span: 24 },
    rules: [{ required: true, message: "活动内容不能为空" }],
  },
  {
    label: "会议图片",
    type: "",
    model: "photo",
    col: { span: 24 },
  },
];

export default {
  components: { ChooseTeamLeader },
  data() {
    return {
      api,
      gridId: "",
      gridAddress: "",
      latitude: "",
      longitude: "",
      options: [],
      options1: [],
      saveType: false,
      dialogImageUrl: "",
      domicileValue: {},
      address: "",
      residenceCode: "",
      dialogVisible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
  },
  methods: {
    // 打开选择团长弹窗
    chooseTeamLeader() {
      this.$refs.chooseTeamLeader.openModal();
    },
    // 分批获取下一级数据
    async loadData(selectedOptions) {
      // console.log(selectedOptions, "361");
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      // 判断当前选项的级别
      switch (selectedOptions.length) {
        case 1:
          // 加载第二级数据
          const res2 = await api.getProvinceRegionInfo(targetOption.value);
          // console.log(res2, "370");
          targetOption.children = res2.data.map((res1) => ({
            label: res1.areaName,
            value: res1.cityCode,
            isLeaf: false,
          }));
          break;
        case 2:
          // 加载第三级数据
          const res3 = await api.getCityRegionInfo(targetOption.value);
          // console.log(res3, "380");
          targetOption.children = res3.data.map((res1) => ({
            label: res1.areaName,
            value: res1.countyCode,
            isLeaf: false,
          }));
          break;
        case 3:
          // 加载第四级数据
          const res4 = await api.getCountyRegionInfo(targetOption.value);
          // console.log(res4, "390");
          targetOption.children = res4.data.map((res1) => ({
            label: res1.areaName,
            value: res1.townCode,
            isLeaf: true, // 最后一级为叶子节点
          }));
          break;
        default:
          break;
      }
      targetOption.loading = false;
      this.options = [...this.options];
    },
    // 获取党组织数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取党组织数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取党组织数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // console.log(value);
      // 获取最后一个ID
      if (e) {
      }
      const lastId = value[value.length - 1];
      const address = e.map((item) => item.label).join("/");
      this.gridAddress = address;
      this.gridId = lastId;
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.kind = 4;
        value.gridId = this.gridId;
        value.openTime = `${value.startTime} ~ ${value.endTime}`;
        value.address = this.address; //位置信息
        value.gridAddress = this.gridAddress;
        value.latitude = this.latitude;
        value.longitude = this.longitude;
        if (!this.gridId) {
          this.$message.error("党组织不能为空");
          return;
        }
        this.saveType = true; // 验证通过后再设置
        try {
          const res = await api.add(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            // 提交成功后关闭弹窗
            this.$emit("closeDialog");
            this.refreshTable();
          } else {
            this.$message.error("新增失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
.button2 {
  display: flex;
  justify-content: space-between;
}
/deep/.ant-form input[type="file"] {
  display: none !important;
}
</style>
    