var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-form',{ref:"formBox",attrs:{"formBuilder":_vm.formBuilder,"data-range-keys":_vm.dateRangeKeys},scopedSlots:_vm._u([{key:"photo",fn:function({ record }){return _c('div',{},[_c('el-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          record.model,
          {
            rules: record.rules,
          },
        ]),expression:"[\n          record.model,\n          {\n            rules: record.rules,\n          },\n        ]"}],staticStyle:{"display":"flex","align-items":"flex-start"},attrs:{"action":"#","limit":1,"auto-upload":false,"list-type":"picture-card"}},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_c('span',[_vm._v(" 最多上传三张 ")]),_c('span',[_vm._v("照片格式小于5M")]),_c('span',[_vm._v("支持格式jpg、png、jpeg")])])]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)}},{key:"leaderName",fn:function({ record }){return _c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          record.model,
          {
            rules: record.rules,
          },
        ]),expression:"[\n          record.model,\n          {\n            rules: record.rules,\n          },\n        ]"}],attrs:{"disabled":"","placeholder":"请输入"}}),_c('ax-button',{staticStyle:{"margin-left":"2%"},on:{"click":_vm.chooseTeamLeader}},[_vm._v("选择人员")])],1)}},{key:"endDate",fn:function({ record }){return _c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          record.model,
          {
            rules: record.rules,
          },
        ]),expression:"[\n          record.model,\n          {\n            rules: record.rules,\n          },\n        ]"}],attrs:{"disabled":"","placeholder":"请输入"}}),_c('ax-button',{staticStyle:{"margin-left":"2%"},on:{"click":_vm.chooseTeamLeader}},[_vm._v("选择人员")])],1)}}])},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options',
          { rules: [{ required: true, message: '请选择' }] },
        ]),expression:"[\n          'options',\n          { rules: [{ required: true, message: '请选择' }] },\n        ]"}],attrs:{"options":_vm.options,"placeholder":"请选择党组织","change-on-select":"","allowClear":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666},on:{"change":_vm.onChange}})],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.closeDialog}},[_vm._v(" 取消 ")])],1),_c('choose-team-leader',{ref:"chooseTeamLeader"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }