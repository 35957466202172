<!--党会管理  -->
<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @action-column-click="actionColumnClick"
      @bulkDeletion="deleteSecurityPersonnel"
      :dynamicSlotNames="['gridAddress', 'gridAddress']"
      @add="add"
      @export="expor"
      @exportAll="exportAll"
      @reset="reset"
      @searchlist="searchlist"
      @pop-confirm="popConfirm"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!--  :showToolBar="true"  表格列设置 -->
      <div slot="gridAddress" style="display: flex">
        <a-cascader
          :options="options"
          change-on-select
          placeholder="请选择所属网格"
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          :model="cuurentAreaCode"
          v-model="cuurentAreaCode1"
        />
      </div>
    </ax-table>
    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="新增党会管理"
      :visible.sync="dialogAdd"
      width="50%"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addPage @closeDialog="dialogAdd = false" @refreshTable="refreshTable"
    /></el-dialog>
    <!-- 编辑跳转弹窗 -->

    <addedit ref="edit" @refreshTable="refreshTable"></addedit>

    <!-- 详情跳转弹窗 -->

    <adddetail ref="detail" @refreshTable="refreshTable"></adddetail>

    <!-- 开始弹窗 -->

    <addstart ref="start" @refreshTable="refreshTable"></addstart>

    <!-- 开始弹窗 -->
  </div>
</template>
    <script>
import api from "./api";
import axTable from "@/components/ax-table";
import addPage from "./addPage.vue";
import addedit from "./addedit.vue";
import adddetail from "./adddetail.vue";
import addstart from "./addstart.vue";
const searchForm = [
  {
    label: "关键字",
    type: "",
    model: "name",
    col: { span: 8 },
    options: { placeholder: "请输入党会名称", showTime: true },
  },
  {
    label: "开始时间",
    type: "datePicker",
    model: "beginDate",
    col: { span: 8 },
    options: { placeholder: "请选择", showTime: true },
  },
  {
    label: "党组织",
    type: "select",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 8 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addPage, addedit, adddetail, axTable, addstart },
  data() {
    //这里存放数据
    return {
      api,
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridAddress: "",
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [{ name: "add", text: "新增党会" }],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "党会名称",
            dataIndex: "name",
            ellipsis: true,
            width: 80,
          },

          {
            title: "预计开始时间",
            dataIndex: "type",
            ellipsis: true,
            width: 80,
          },
          {
            title: "预计结束时间",
            dataIndex: "managerName",
            ellipsis: true,
            width: 80,
          },
          {
            title: "会议类型",
            dataIndex: "gridAddress",
            ellipsis: true,
            width: 150,
          },

          {
            title: "会议地点",
            dataIndex: "contact",
            ellipsis: true,
            width: 120,
          },
          {
            title: "主持人",
            dataIndex: "address",
            ellipsis: true,
            width: 250,
          },
          {
            title: "党组织",
            dataIndex: "number",
            ellipsis: true,
            width: 80,
          },
          {
            title: "实到应到",
            dataIndex: "safetyDesc",
            ellipsis: true,
            width: 80,
          },
          {
            title: "状态",
            dataIndex: "transport",
            ellipsis: true,
            width: 120,
          },
        ],
        true,
        {
          width: 250,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  type: "#E6A23C ",
                  name: "detail",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "编辑",
                  type: "#556bff",
                  name: "edit",
                  link: true,
                  popDisabled: true,
                };
              },
            },

            {
              props: (record) => {
                return {
                  text: "开始",
                  name: "start",
                  link: true,
                  type: "#FA5959",
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  link: true,
                  type: "#FA5959",
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: {},
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      const address = e.map((item) => item.label).join("/");
      this.gridAddress = address;
      this.cuurentAreaCode = value[value.length - 1];
      this.cuurentAreaCode1 = value;
    },
    //重置
    reset(e) {
      this.cuurentAreaCode = "";
      this.cuurentAreaCode1 = [];
      if (this.$refs.cascader) {
        this.$refs.cascader.$refs.input.value = "";
      }
      this.gridAddress = ""; // 清空 gridAddress 参数
    },

    // 在搜索方法中根据情况传入或不传入 gridAddress 参数
    searchlist(e) {
      const formValues = { ...e.formValues };
      e.formValues.gridAddress = this.gridAddress || undefined;
      this.$refs.roleTable.getDataSource(e.formValues);
    },
    // 导出全部
    exportAll() {
      const values = JSON.parse(JSON.stringify({ kind: 1 }));
      api.export({
        fileName: "学校信息.xls",
        params: values,
      });
    },
    // 导出筛选
    expor() {
      this.searchForm.formValues.gridAddress = this.gridAddress || undefined;
      this.searchForm.formValues.kind = 1;
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      api.export({
        fileName: "党会管理.xls",
        params: values,
      });
    },
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //详情
        case "detail":
          this.$refs.detail.openModal(record);
          break;
        //编辑
        case "edit":
          this.$refs.edit.openModal(record);
          break;
        //开始
        case "start":
          this.$refs.start.openModal(record);
          break;
        //删除
        case "delete":
          break;
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.ids(that.selectedRowKeys.join(","));
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.roleTable.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>